<template>
  <div class="delivery-address">
    <div class="delivery-address-info">
      <span v-if="!addressLink" v-html="addressDetail"></span>
      <a :href="addressLink" target="_blank" v-else v-html="addressDetail"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XDeliveryAddress',
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  computed: {
    addressLink() {
      if (this.address?.lat && this.address?.lng) {
        return `https://www.google.com/maps/search/?api=1&query=${this.address?.lat},${this.address?.lng}`
      }
      return null
    },
    addressDetail() {
      let address = this.address
      let addressInfo = ''
      if (address) {
        addressInfo = `${address?.receiver_name} ${address?.phone} <br/> ${address?.address_name}: `
      }
      let addressDetail = [
        address?.province?.name,
        address?.district?.name,
        address?.street,
        address?.house_number,
      ]
      addressInfo += addressDetail
        .filter((value) => value != null || value != undefined)
        .join(', ')
      return addressInfo
    },
  },
}
</script>

<style lang="scss" scoped>
.delivery-address-info {
  a {
    color: var(--cui-body-color);
  }
}
</style>
