<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler
        class="ps-1"
        @click="$store.commit('coreui/toggleSidebar')"
      >
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        {{ $t('common.app_name') }}
      </CHeaderBrand>
      <CHeaderNav>
        <CNavItem v-if="shop">
          <CNavLink component="label">{{ shop.ref_shop_name }}</CNavLink>
        </CNavItem>
        <CNavItem>
          <AppLanguageSwitcher />
        </CNavItem>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt.vue'
import AppLanguageSwitcher from './AppLanguageSwitcher.vue'
import { logo } from '@/assets/brand/logo'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
    AppLanguageSwitcher,
  },
  data() {
    return {
      logo,
    }
  },
  computed: {
    ...mapFields('auth', ['shop']),
  },
}
</script>
<style>
.ysl-flag {
  width: 32px;
  height: auto;
}
</style>
