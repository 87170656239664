export default [
  {
    component: 'CNavItem',
    name: 'sidebar.dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavTitle',
    name: 'sidebar.main_feature',
  },
  {
    component: 'CNavItem',
    name: 'sidebar.orders',
    to: '/orders',
    icon: 'cil-file',
  },
  {
    component: 'CNavGroup',
    name: 'sidebar.catalog',
    to: '/catalog',
    icon: 'cil-storage',
    items: [
      {
        component: 'CNavItem',
        name: 'sidebar.products',
        to: '/catalog/products',
        icon: 'cil-arrow-right',
      },
      {
        component: 'CNavItem',
        name: 'sidebar.attributes',
        to: '/catalog/attributes',
        icon: 'cil-arrow-right',
      },
      {
        component: 'CNavItem',
        name: 'sidebar.categories',
        to: '/catalog/categories',
        icon: 'cil-arrow-right',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'sidebar.system',
    to: '/system',
    icon: 'cil-settings',
    items: [
      {
        component: 'CNavItem',
        name: 'sidebar.settings',
        to: '/system/settings',
        icon: 'cil-arrow-right',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'sidebar.reports',
    to: '/reports',
    icon: 'cil-chart',
    items: [
      {
        component: 'CNavItem',
        name: 'sidebar.report',
        to: '/reports/order',
        icon: 'cil-arrow-right',
      },
    ],
  },
]
