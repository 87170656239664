<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'coreui/updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand class="d-flex gap-2">
      <img :src="avatar" style="width: 30px" />
      {{ $t('common.app_name') }}
    </CSidebarBrand>
    <AppSidebarNav />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import avatar from '@/assets/images/logo.png'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      avatar,
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.coreui.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.coreui.sidebarVisible),
    }
  },
}
</script>
