import axios from '@/axios'
import { getField, updateField } from 'vuex-map-fields'
import routes from '@/config/routes'

const { attribute } = routes

const state = () => {
  return {
    items: [],
    includes: [],
    pagination: {
      current: 1,
      total: 0,
      next: null,
      previous: null,
    },
    search: null,
    group_attribute: null,
    attribute: {
      id: null,
      name: null,
      name_zh: null,
      name_km: null,
      is_visible: true,
      ordering: null,
      image: null,
      image_url: null,
    },
    groupAttributes: [],
  }
}

const getters = {
  getField,
}

const mutations = {
  updateField,
}

const actions = {
  listAttributes(_, payload) {
    let url = attribute.list
    if (Object.keys(payload).length != 0) {
      url = url + '?' + new URLSearchParams(payload).toString()
    }
    return axios.get(url, payload)
  },
  showAttribute(_, { id }) {
    return axios.get(`${attribute.show}/${id}`)
  },
  storeAttribute(_, params) {
    return axios.post(`${attribute.store}`, params)
  },
  updateAttribute(_, { id, params }) {
    return axios.post(`${attribute.update}/${id}`, params)
  },
  deleteAttribute(_, { id }) {
    return axios.delete(`${attribute.delete}/${id}`)
  },
  getGroupAttributes() {
    return axios.get(`${attribute.groupAttributes}`)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
