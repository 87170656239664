<template>
  <VueMultiselect
    v-bind="$attrs"
    :options="options"
    :select-label="$t('common.select_enter_to_select')"
    :deselect-label="$t('common.select_enter_to_remove')"
    :placeholder="$t('common.select_option')"
    :no-result="$t('common.select_no_result')"
    :no-options="$t('common.select_no_options')"
    :selected-label="$t('common.select_selected_label')"
  >
  </VueMultiselect>
</template>

<script>
export default {
  name: 'XMultiSelect',
  props: {
    options: {
      type: Array,
      default() {
        return ['Backend', 'Frontend', 'API']
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>
