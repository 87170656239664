<template>
  <CRow>
    <CCol :md="12">
      <CCard class="mb-4">
        <CCardHeader component="h5" v-if="title">{{ title }}</CCardHeader>
        <CCardBody>
          <slot></slot>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'XCard',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
