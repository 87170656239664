import { getField, updateField } from 'vuex-map-fields'
import axios from '@/axios'
import routes from '@/config/routes'

const { subcategory } = routes

const state = () => {
  return {
    items: [],
    includes: [],
    pagination: {
      current: 1,
      total: 0,
      next: null,
      previous: null,
    },
    search: null,
    subcategory: {
      id: null,
      name: null,
      name_zh: null,
      name_km: null,
      is_active: false,
      ordering: null,
      image_url: null,
      parent_id: null,
    },
  }
}

const getters = {
  getField,
}

const mutations = {
  updateField,
  handleResetForm(state) {
    state.subcategory.id = null
    state.subcategory.name = null
    state.subcategory.name_zh = null
    state.subcategory.name_km = null
    state.subcategory.is_active = false
    state.subcategory.ordering = null
    state.subcategory.parent_id = null
    state.subcategory.image_url = null
  },
}

const actions = {
  subcategory(_, { parent_id }) {
    return axios.get(`${subcategory.list}/${parent_id}`)
  },
  showSubcategory(_, { id }) {
    return axios.get(`${subcategory.show}/${id}`)
  },
  storeSubcategory(_, params) {
    return axios.post(`${subcategory.store}`, params)
  },
  updateSubcategory(_, { id, params }) {
    return axios.post(`${subcategory.update}/${id}`, params)
  },
  deleteSubcategory(_, { id }) {
    return axios.delete(`${subcategory.delete}/${id}`)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
