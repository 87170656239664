import axios from '@/axios'
import { getField, updateField } from 'vuex-map-fields'
import routes from '@/config/routes'

const { productImage } = routes

const state = () => {
  return {
    images: [],
    pagination: {
      current: 1,
      total: 0,
      next: null,
      previous: null,
    },
  }
}

const getters = {
  getField,
}

const mutations = {
  updateField,
}

const actions = {
  getImages(_, { product_id, page }) {
    return axios.get(`${productImage.list}/${product_id}?page=${page}`)
  },
  uploadImage(_, { product_id, images }) {
    return axios.post(`${productImage.upload}/${product_id}`, images)
  },
  deleteImage(_, { image_id }) {
    return axios.delete(`${productImage.delete}/${image_id}`)
  },
  deleteImages(_, { image_ids }) {
    return axios.post(`${productImage.deleteImages}`, {
      images: image_ids,
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
