import { getField, updateField } from 'vuex-map-fields'
import axios from '@/axios'
import routes from '@/config/routes'

const { category } = routes

const state = () => {
  return {
    items: [],
    includes: [],
    pagination: {
      current: 1,
      total: 0,
      next: null,
      previous: null,
    },
    search: null,
    category: {
      id: null,
      name: null,
      name_zh: null,
      name_km: null,
      is_active: false,
      ordering: null,
      image_url: null,
    },
  }
}

const getters = {
  getField,
}

const mutations = {
  updateField,
  handleResetForm(state) {
    state.category.id = null
    state.category.name = null
    state.category.name_zh = null
    state.category.name_km = null
    state.category.is_active = false
    state.category.ordering = null
    state.category.image_url = null
  },
}

const actions = {
  listCategory(_, payload) {
    let url = category.list
    if (payload && Object.keys(payload).length != 0) {
      url = url + '?' + new URLSearchParams(payload).toString()
    }
    return axios.get(url, payload)
  },
  storeCategory(_, params) {
    return axios.post(`${category.store}`, params)
  },
  updateCategory(_, { id, params }) {
    return axios.post(`${category.update}/${id}`, params)
  },
  showCategory(_, { id }) {
    return axios.get(`${category.show}/${id}`)
  },
  deleteCategory(_, { id }) {
    return axios.delete(`${category.delete}/${id}`)
  },
  searchCategories(_, payload) {
    let url = category.searchCategories
    if (payload && Object.keys(payload).length != 0) {
      url = url + '?' + new URLSearchParams(payload).toString()
    }
    return axios.get(url, payload)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
