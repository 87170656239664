<template>
  <CModal
    :visible="mediaLibraryModal"
    size="xl"
    backdrop="static"
    @close="handleClose"
  >
    <CModalHeader>
      <CModalTitle>
        {{ $t('common.media_library') }}
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CRow class="media-library">
        <CCol
          sm="12"
          md="9"
          :class="{
            'd-flex justify-content-center': images && images.length == 0,
          }"
        >
          <template v-if="images && images.length == 0">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <XSvgNoFiles width="210px" height="210px" />
              <p>{{ $t('common.no_files') }}</p>
            </div>
          </template>
          <div class="list-images">
            <div
              class="item-image position-relative"
              v-for="(image, key) in images"
              :key="key"
            >
              <div class="media-library-tools">
                <div
                  class="media-library-tools__marker"
                  @click="handleSelectImage(image)"
                >
                  <template
                    v-if="selectedImages.some((item) => item.id === image.id)"
                  >
                    <CIcon
                      icon="cilCheckCircle"
                      size="lg"
                      class="text-white rounded-full bg-primary"
                    />
                  </template>
                  <template v-else>
                    <CIcon icon="cilCircle" size="lg" class="text-secondary" />
                  </template>
                </div>
              </div>
              <img :src="image.path" />
            </div>
          </div>
          <div class="mt-3" v-if="pagination.total && pagination.total > 1">
            <XPagination
              :pages="pagination.total"
              v-model="pagination.current"
              :limit="3"
              align="center"
            />
          </div>
        </CCol>
        <CCol sm="12" md="3">
          <div
            :class="{ uploader: true, 'border-danger': fileSizeExceeded }"
            @click="() => $refs.file.click()"
            style="position: relative"
          >
            <XOverlay v-if="isUploading" style="width: 60px; height: auto" />
            <div
              class="text-center d-flex flex-column gap-2 align-items-center"
            >
              <input
                type="file"
                ref="file"
                accept="image/jpeg,image/jpg,image/png"
                class="hidden"
                @change="handleUploadFile"
                multiple
              />
              <CIcon
                icon="cilCloudUpload"
                size="xl"
                :class="{
                  'text-secondary': true,
                  'text-danger': fileSizeExceeded,
                }"
              />
              <p
                :class="{
                  'text-secondary mb-0': true,
                  'text-danger': fileSizeExceeded,
                }"
              >
                {{ $t('common.click_here_to_upload_image') }}
              </p>
            </div>
          </div>
          <p
            v-if="fileSizeExceeded"
            class="text-danger m-0 p-0 mt-1 text-small"
          >
            *
            {{ $t('common.file_exceeded_limit', { size: maxFileSize }) }}
          </p>
        </CCol>
      </CRow>
    </CModalBody>
    <CModalFooter>
      <CButton
        color="primary"
        @click="insert"
        :disabled="!selectedImages.length > 0"
      >
        {{ $t('button.insert') }}
      </CButton>
      <CButton
        color="danger"
        @click="handleDeleteImages"
        :disabled="!selectedImages.length > 0"
      >
        <CIcon icon="cilTrash" /> {{ $t('button.delete') }}
      </CButton>
    </CModalFooter>
  </CModal>
</template>

<script>
import { mapActions } from 'vuex'
import XOverlay from '@/components/Utils/XOverlay.vue'
import { mapFields } from 'vuex-map-fields'
import XSvgNoFiles from '../Svgs/XSvgNoFiles.vue'

export default {
  name: 'XMediaLibrary',
  components: { XOverlay, XSvgNoFiles },
  data() {
    return {
      mediaLibraryModal: false,
      editor: null,
      selectedImages: [],
      maxFileSize: 0,
      fileSizeExceeded: false,
      isUploading: false,
      config: process.env,
    }
  },
  computed: {
    ...mapFields('productImages', ['images', 'pagination']),
  },
  watch: {
    'pagination.current'() {
      console.log(this.selectedImages)
      this.getImagesList(this.pagination.current)
    },
  },
  methods: {
    ...mapActions('product', ['getProductImages']),
    ...mapActions('productImages', [
      'getImages',
      'deleteImages',
      'uploadImage',
    ]),
    toggle(options) {
      const { open, editor } = options

      if (open) this.mediaLibraryModal = true
      else this.mediaLibraryModal = !this.mediaLibraryModal

      if (editor) this.editor = editor
    },
    handleClose() {
      this.mediaLibraryModal = false
    },
    insert() {
      let rawHtml = ''
      if (this.editor) {
        if (this.selectedImages && this.selectedImages.length) {
          this.selectedImages.forEach((image) => {
            rawHtml += `<img src='${image.path}' style='width: 100%; height: auto' />`
          })
          this.editor.insertContent(rawHtml)
          this.$nextTick(() => {
            this.selectedImages = []
            this.mediaLibraryModal = false
          })
        }
      }
    },
    handleUploadFile(e) {
      this.fileSizeExceeded = false
      const files = e.target.files
      const { VUE_APP_PRODUCT_IMAGE_LIMIT } = this.config
      this.maxFileSize = parseFloat(VUE_APP_PRODUCT_IMAGE_LIMIT)
      for (let i = 0; i < files.length; i++) {
        if (Math.ceil(files[i].size / 1024) > this.maxFileSize) {
          this.fileSizeExceeded = true
          this.$refs.file.value = null
          return
        }
      }

      const params = new FormData()
      for (let i = 0; i < files.length; i++) {
        params.append('images[]', files[i])
      }

      this.$refs.file.value = null
      this.isUploading = true
      this.uploadImage({ product_id: this.$route.params.id, images: params })
        .then(() => {
          this.getImagesList()
          this.onResponseSuccess()
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.isUploading = false
        })
    },
    getImagesList(page = 1) {
      this.getImages({ product_id: this.$route.params.id, page }).then(
        ({ data }) => {
          if (data.data) {
            this.images = data.data
            this.pagination = data.pagination
          }
        },
      )
    },
    handleSelectImage(image) {
      const _image = this.selectedImages.find((img) => img.id == image.id)
      if (_image) {
        this.selectedImages.splice(this.selectedImages.indexOf(_image), 1)
      } else {
        this.selectedImages.push(image)
      }
    },
    handleDeleteImages(e) {
      e.preventDefault()

      this.onConfirm(
        {
          title: this.$t('common.modal.delete_product_images'),
          text: this.$t('common.modal.confirmation_delete_product_images'),
        },
        () => {
          const image_ids = this.selectedImages.map((image) => image.id)
          this.$isLoading(true)
          this.deleteImages({ image_ids })
            .then(() => {
              this.onDeleteSuccess(() => {
                this.selectedImages = []
                this.getImagesList()
              })
            })
            .catch((error) => {
              this.onResponseError(error)
            })
            .finally(() => {
              this.$isLoading(false)
            })
        },
      )
    },
  },
  mounted() {
    this.getImagesList()
  },
}
</script>

<style lang="scss" scoped>
.media-library {
  & .uploader {
    width: 100%;
    height: 230px;
    border: 2px dashed #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f1f1f1;
    cursor: pointer;
    overflow: hidden;
  }

  & .hidden {
    display: none;
  }

  & .list-images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;
    & .item-image {
      border: 1px solid #dddddd;
      height: 120px;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .btn-common {
        padding: 4px;
        font-size: 10px !important;
        visibility: hidden;
      }

      &:hover {
        .btn-common {
          visibility: visible;
        }
      }
    }
  }

  & .right-2 {
    right: 10px;
  }

  & .top-2 {
    top: 10px;
  }

  & .rounded-full {
    border-radius: 50%;
  }
}

.single-line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-small {
  font-size: 12px;
}

.media-library-tools {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: column-reverse;
}
.media-library-tools__marker {
  cursor: pointer;
}
</style>
