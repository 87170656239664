<template>
  <div class="d-flex flex-column gap-3">
    <CInputGroup>
      <CInputGroupText id="en-input">
        <img :src="en" class="img-flag" />
      </CInputGroupText>
      <CFormInput
        :placeholder="$t('languages.english')"
        :aria-label="$t('languages.english')"
        aria-describedby="languages-english"
        :value="nameEn"
        @input="$emit('update:nameEn', $event.target.value)"
        :invalid="checkIfKeyExist(validations, 'name')"
        :feedback-invalid="getFeedback(validations, 'name')"
        required
      />
    </CInputGroup>
    <CInputGroup>
      <CInputGroupText id="en-input">
        <img :src="zh" class="img-flag" />
      </CInputGroupText>
      <CFormInput
        :placeholder="$t('languages.chinese')"
        :aria-label="$t('languages.chinese')"
        aria-describedby="languages-chinese"
        :value="nameZh"
        @input="$emit('update:nameZh', $event.target.value)"
      />
    </CInputGroup>
    <CInputGroup>
      <CInputGroupText id="en-input">
        <img :src="km" class="img-flag" />
      </CInputGroupText>
      <CFormInput
        :placeholder="$t('languages.khmer')"
        :aria-label="$t('languages.khmer')"
        aria-describedby="languages-khmer"
        :value="nameKm"
        @input="$emit('update:nameKm', $event.target.value)"
      />
    </CInputGroup>
  </div>
</template>

<script>
import km from '@/assets/flags/km.png'
import en from '@/assets/flags/en.png'
import zh from '@/assets/flags/zh.png'
export default {
  emits: ['update:nameEn', 'update:nameZh', 'update:nameKm'],
  props: {
    nameEn: {
      type: String,
      default: null,
    },
    nameKm: {
      type: String,
      default: null,
    },
    nameZh: {
      type: String,
      default: null,
    },
    validations: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      km,
      en,
      zh,
    }
  },
}
</script>

<style lang="scss" scoped>
.img-flag {
  width: 25px;
  height: auto;
  object-fit: contain;
}
</style>
