import {
  cilArrowRight,
  cilArrowLeft,
  cilBasket,
  cilSettings,
  cilBriefcase,
  cilTrash,
  cilSearch,
  cilColorBorder,
  cilPlus,
  cilUser,
  cilLockLocked,
  cilMenu,
  cilHome,
  cilChart,
  cilSpeedometer,
  cilStorage,
  cilCamera,
  cilSave,
  cilCloudUpload,
  cilAudioSpectrum,
  cilCheckCircle,
  cilXCircle,
  cilCircle,
  cilVideo,
  cilImage,
  cilPeople,
  cilUserFollow,
  cilFile,
  cilArrowThickToBottom,
  cilArrowThickFromBottom,
  cilBike,
  cilCheck,
  cilHistory,
  cilClone,
  cilBuilding,
  cilCommand,
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilArrowRight,
    cilArrowLeft,
    cilBasket,
    cilSettings,
    cilBriefcase,
    cilTrash,
    cilSearch,
    cilColorBorder,
    cilPlus,
    cilUser,
    cilLockLocked,
    cilMenu,
    cilHome,
    cilChart,
    cilSpeedometer,
    cilStorage,
    cilCamera,
    cilSave,
    cilCloudUpload,
    cilAudioSpectrum,
    cilCheckCircle,
    cilXCircle,
    cilCircle,
    cilVideo,
    cilImage,
    cilPeople,
    cilUserFollow,
    cilFile,
    cilArrowThickToBottom,
    cilArrowThickFromBottom,
    cilBike,
    cilCheck,
    cilHistory,
    cilClone,
    cilBuilding,
    cilCommand,
  },
)
