<template>
  <div class="x-overlay">
    <div class="x-overlay__box">
      <XSvgTubeSpinner v-bind="$attrs" />
    </div>
  </div>
</template>

<script>
import XSvgTubeSpinner from '@/components/Svgs/XSvgTubeSpinner.vue'
export default {
  inheritAttrs: false,
  name: 'XOverlay',
  components: { XSvgTubeSpinner },
}
</script>

<style lang="scss" scoped>
.x-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: #00000080;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &__box {
    background: whitesmoke;
    border-radius: 4px;
    padding: 8px 10px;
  }
}
</style>
