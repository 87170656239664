<template>
  <div class="x-upload-wrapper gap-2">
    <div
      class="x-upload cursor-pointer"
      @change="handleFileChange"
      @click="() => $refs.file.click()"
    >
      <input type="file" class="hidden" ref="file" multiple />
      <CIcon name="cilCamera" size="xl" />
    </div>
    <div class="x-upload" v-for="(image, index) in images" :key="index">
      <img :src="image" @error="(e) => (e.target.src = logo)" />
      <span
        class="btn btn-danger btn-sm"
        @click="() => images.splice(index, 1)"
      >
        <CIcon icon="cilTrash" />
      </span>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/images/logo.png'
export default {
  name: 'XFileUpload',
  props: {},
  data() {
    return {
      images: [
        'https://img.alicdn.com/i1/2598318143/O1CN018ZcIkV2A1XuSWktA7_!!2598318143.jpg',
        'http://img.alicdn.com/imgextra/i1/18139021/O1CN01UOnELW2GVffaqdLV7_!!18139021.jpg',
      ],
      logo,
    }
  },
  methods: {
    handleFileChange(e) {
      const images = e.target.files
      if (images && images.length) {
        for (var i = 0; i < images.length; i++) {
          this.images.push(URL.createObjectURL(images[i]))
        }
      }
      this.$refs.file.value = null
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}
.x-upload-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.x-upload {
  background: #f1f1f1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px double #ddd;
  width: 150px;
  height: 150px;
  justify-content: center;
  position: relative;
  & span {
    position: absolute;
    top: 10px;
    right: 10px;
    visibility: hidden;
  }

  &:hover {
    & span {
      visibility: visible;
    }
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}
</style>
