import axios from '@/axios'
import routes from '@/config/routes'

const { order: api } = routes

const ORDERS = 'orders'
const DETAIL = 'detail'
const SHIPPED_HOUSE = 'shipped_house'

const state = () => ({
  orders: {
    data: {
      items: [],
      order_count: 0,
      order_items_count: 0,
      resources: {},
    },
    pagination: {
      current: null,
      next: null,
      previous: null,
      total: null,
    },
  },
  detail: {
    customer: null,
    order: null,
    status_histories: null,
  },
  shipped_house: {
    data: null,
    includes: null,
    pagination: null,
  },
})

const actions = {
  async fetchOrders({ commit }, params) {
    const url = api.list
    const response = await axios.get(url, { params })

    commit(ORDERS, response.data)
  },

  async getOrderDetail({ commit }, orderId) {
    const url = api.detail + `/${orderId}`
    const response = await axios.get(url)
    commit(DETAIL, response.data)
  },

  async getOrderShippedHouse({ commit }, shippedHouseId) {
    const url = api.shipped_house + `/${shippedHouseId}/shipped-house`
    const response = await axios.get(url)
    commit(SHIPPED_HOUSE, response.data)
  },

  acceptOrderItems(_, payload) {
    const url = api.accept_order
    return axios.post(url, payload)
  },

  cancelReceiveOrderItems(_, payload) {
    const url = api.cancel_receive_order_items
    return axios.post(url, payload)
  },

  cancelPurchasedOrderItems(_, payload) {
    const url = api.cancel_purchased_order_items
    return axios.post(url, payload)
  },

  requestOriginDelivery(_, payload) {
    const url = api.request_origin_delivery
    return axios.post(url, payload)
  },
}

const mutations = {
  [ORDERS](state, value) {
    state.orders = value
  },
  [DETAIL](state, value) {
    state.detail = value
  },
  [SHIPPED_HOUSE](state, value) {
    state.shipped_house = value
  },
}

const getters = {
  getOrders: (state) => state.orders,
  getOrderDetail: (state) => state.detail,
  getOrderShippedHouse: (state) => state.shipped_house,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
