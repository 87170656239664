import axios from '@/axios'
import { $cookies } from '@/cookies'
import { getField, updateField } from 'vuex-map-fields'
import routes from '@/config/routes'

const { auth } = routes

const state = () => {
  return {
    user: null,
    accessToken: null,
    isAuthenticated: false,
    avatar: '//gravatar.com/avatar/youngsia',
    shop: null,
  }
}

const getters = {
  getField,
  user: (state) => state.user,
  accessToken: (state) => state.accessToken,
  isAuthenticated: (state) => state.isAuthenticated,
  avatar: (state) => state.avatar,
  shop: (state) => state.shop,
}

const mutations = {
  updateField,
  onLogged(state, payload) {
    const { isAuthenticated } = payload
    state.isAuthenticated = isAuthenticated
  },
  setUserData(state, payload) {
    const { user, access_token, shop } = payload

    state.user = user
    state.accessToken = access_token
    state.isAuthenticated = true
    state.shop = shop

    axios.defaults.headers.common['X-Access-Token'] = access_token
    $cookies.set('access_token', state.accessToken)
  },
}

const actions = {
  onLogged({ commit }, payload) {
    commit('onLogged', payload)
  },
  getUserData() {
    const accessToken = $cookies.get('access_token')
    axios.defaults.headers.common['X-Access-Token'] = accessToken

    return axios.get(auth.getUserData)
  },
  setUserData({ commit }, payload) {
    commit('setUserData', payload)
  },
  updateSellerProfile(_, payload) {
    return axios.post(auth.updateSellerProfile, payload)
  },
  uploadSellerProfile(_, payload) {
    return axios.post(auth.uploadSellerProfile, payload)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
