import axios from '@/axios'
import routes from '@/config/routes'
import { getField } from 'vuex-map-fields'

const { orderReport } = routes

const state = () => {
  return {
    //
  }
}

const getters = {
  getField,
}

const mutations = {}

const actions = {
  listOrderReport(_, payload) {
    let url = orderReport.list
    if (Object.keys(payload).length != 0) {
      url = url + '?' + new URLSearchParams(payload).toString()
    }
    return axios.get(url, payload)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
