<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <div class="avatar avatar-md">
        <img
          :src="userPhoto"
          @error="onErrorImage"
          alt="User avatar"
          class="avatar-img"
        />
        <span class="avatar-status bg-primary"></span>
      </div>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        {{ user ? user.name : $t('common.account') }}
      </CDropdownHeader>
      <CDropdownItem
        component="button"
        @click="() => $router.push({ name: 'account' })"
      >
        <CIcon icon="cil-user" /> {{ $t('common.profile') }}
      </CDropdownItem>
      <CDropdownItem
        component="button"
        class="bg-danger text-white"
        @click="logout"
      >
        <CIcon icon="cil-lock-locked" /> {{ $t('button.logout') }}
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import { $cookies } from '@/cookies'
import { mapGetters } from 'vuex'
export default {
  name: 'AppHeaderDropdownAccnt',
  computed: {
    ...mapGetters('auth', ['user', 'avatar']),
    userPhoto() {
      if (this.user && this.user.photo) {
        return this.user.photo
      }
      return this.avatar
    },
  },
  methods: {
    logout() {
      $cookies.remove('access_token')

      this.$store.dispatch('auth/onLogged', {
        isAuthenticated: false,
      })

      this.$router.push({
        name: 'login',
      })
    },
    onErrorImage(e) {
      e.target.src = this.avatar
    },
  },
}
</script>
