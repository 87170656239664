<template>
  <CInputGroupText>
    {{ baseCurrencyCode }}
  </CInputGroupText>
</template>

<script>
export default {
  name: 'XCurrencyGroupText',
}
</script>

<style lang="scss" scoped></style>
