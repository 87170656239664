import VueCookies from 'vue3-cookies'

let $cookies

export default (app) => {
  app.use(VueCookies)
  $cookies = app.config.globalProperties.$cookies
}

export { $cookies }
