<template>
  <div class="invalid-feedback" v-if="checkIfKeyExist(validations, field)">
    {{ getFeedback(validations, field) }}
  </div>
</template>

<script>
export default {
  name: 'XFeedback',
  props: {
    validations: {
      type: Object,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
