import axios from '@/axios'
import { getField, updateField } from 'vuex-map-fields'
import routes from '@/config/routes'

const { groupAttribute } = routes

const state = () => {
  return {
    items: [],
    includes: [],
    pagination: {
      current: 1,
      total: 0,
      next: null,
      previous: null,
    },
    search: null,
    groupAttribute: {
      id: null,
      name: null,
      name_zh: null,
      name_km: null,
      is_visible: true,
      ordering: null,
    },
  }
}

const getters = {
  getField,
}

const mutations = {
  updateField,
}

const actions = {
  listGroupAttributes(_, payload) {
    let url = groupAttribute.list
    if (Object.keys(payload).length != 0) {
      url = url + '?' + new URLSearchParams(payload).toString()
    }
    return axios.get(url, payload)
  },
  showGroupAttribute(_, { id }) {
    return axios.get(`${groupAttribute.show}/${id}`)
  },
  storeGroupAttribute(_, params) {
    return axios.post(`${groupAttribute.store}`, params)
  },
  updateGroupAttribute(_, { id, params }) {
    params = { ...params, _method: 'PUT' }
    return axios.post(`${groupAttribute.update}/${id}`, params)
  },
  deleteGroupAttribute(_, { id }) {
    return axios.delete(`${groupAttribute.delete}/${id}`)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
