<template>
  <CBreadcrumb class="d-md-down-none me-auto mb-0">
    <CBreadcrumbItem
      v-for="(item, idx) in breadcrumbs"
      :key="item"
      :href="item.active ? '' : item.path"
      :active="item.active"
      :hidden="item.name == breadcrumbs[idx - 1]?.name"
    >
      {{ $t(`sidebar.${item.name?.toLowerCase()}`) }}
    </CBreadcrumbItem>
  </CBreadcrumb>
</template>

<script>
import { onMounted, ref } from 'vue'
import router from '@/router'

export default {
  name: 'AppBreadcrumb',
  setup() {
    const breadcrumbs = ref()

    const getBreadcrumbs = () => {
      const routes = router.currentRoute.value.matched
      return routes.map((route, key) => {
        let path = `${router.options.history.base}${route.path}`
        let active = route.path === router.currentRoute.value.fullPath
        if (key === routes.length - 1) {
          path = router.currentRoute.value.fullPath
          active = true
        }

        return {
          active,
          name: route.name,
          path,
        }
      })
    }

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    return {
      breadcrumbs,
    }
  },
}
</script>
