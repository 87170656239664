<template>
  <CIcon
    v-if="visibility"
    class="text-primary"
    size="lg"
    icon="cilCheckCircle"
  />
  <CIcon v-else class="text-danger" size="lg" icon="cilXCircle" />
</template>

<script>
export default {
  name: 'XVisibility',
  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
