import i18n from '@/i18n'

const state = () => {
  return {
    options: [
      { id: 'male', label: i18n.global.t('common.male') },
      { id: 'female', label: i18n.global.t('common.female') },
    ],
  }
}

const getters = {
  options: (state) => state.options,
}

const mutations = {}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
