<template>
  <router-view />
</template>

<script>
import { $cookies } from './cookies'

export default {
  name: 'App',
  beforeCreate() {
    let locale = $cookies.get('locale')
    this.$i18n.locale = !locale ? 'en' : locale
  },
  watch: {
    '$i18n.locale'() {
      this.setAxiosDefaultXLocal()
    },
  },
  methods: {
    setAxiosDefaultXLocal() {
      this.$axios.defaults.headers.common['X-Locale'] = this.$i18n.locale
    },
  },
  mounted() {
    this.setAxiosDefaultXLocal()
  },
}
</script>

<style lang="scss">
@import 'styles/style';
</style>
