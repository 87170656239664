<template>
  <CFooter>
    <div>
      <a href="" target="_blank">
        {{ $t('common.app_name') }}
      </a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} {{ $t('common.company') }}</span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">{{ $t('common.powered_by') }}</span>
      <a href="">{{ $t('common.company') }}</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
