<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center mb-5"
  >
    <Vue3Lottie
      :animation-data="LottieFilesCoding"
      height="auto"
      width="30vw"
    />
    <h4>{{ title || $t('common.under_development') }}</h4>
  </div>
</template>

<script>
import LottieFilesCoding from '@/assets/lottiefiles/coding.json'
export default {
  name: 'AppUnderDevelopment',
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      LottieFilesCoding,
    }
  },
}
</script>

<style lang="scss" scoped></style>
