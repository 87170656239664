import axios from '@/axios'
import { getField, updateField } from 'vuex-map-fields'
import routes from '@/config/routes'

const { product } = routes

const defaultProductStates = {
  product_type: null,
  product_name: null,
  product_name_zh: null,
  product_name_km: null,
  product_description: null,
  product_min_quantity: null,
  batch_lot_quantity: null,
  exchange_rate: null,
  origin_delivery_fee: null,
  origin_delivery_fee_local_currency: null,
  product_price: null,
  product_local_currency_price: null,
  stock_quantity: null,
  product_skus: [],
  product_skus_locale: [],
  product_sku_maps: [],
  product_sku_maps_locale: [],
  product_properties: [],
  product_properties_locale: { km: [], zh: [] },
  attributes: [],
  defaultProductStockQuantity: null,
  categories: [],
}

const state = () => {
  return {
    items: [],
    includes: [],
    pagination: {
      current: 1,
      total: 0,
      next: null,
      previous: null,
    },
    search: null,
    product: { ...defaultProductStates },
    productAttributes: [],
    productVideos: [],
    productImages: [],
    productBinaryImages: [],
    productCurrentImages: [],
    validations: {},
    createForm: {
      productAttributes: [],
      attributes: [],
    },
    productCurrentVideos: [],
  }
}

const getters = {
  getField,
}

const mutations = {
  updateField,
  resetProductState(state) {
    state.product = { ...defaultProductStates }
    state.productAttributes = []
    state.productVideos = []
    state.productImages = []
    state.productBinaryImages = []
    state.validations = {}
  },
}

const actions = {
  listProducts(_, payload) {
    let url = product.list
    if (Object.keys(payload).length != 0) {
      url = url + '?' + new URLSearchParams(payload).toString()
    }
    return axios.get(url, payload)
  },
  getCategory() {
    return axios.get(`${product.category}`)
  },
  showProduct(_, { product_id }) {
    return axios.get(`${product.show}/${product_id}`)
  },
  storeProduct(_, params) {
    return axios.post(`${product.store}`, params)
  },
  updateProduct(_, { product_id, params }) {
    return axios.post(`${product.update}/${product_id}`, params)
  },
  deleteProduct(_, { product_id }) {
    return axios.delete(`${product.delete}/${product_id}`)
  },
  getGroupAttributes() {
    return axios.get(`${product.groupAttributes}`)
  },
  deleteImages(_, { product_id, images }) {
    return axios.post(`${product.deleteImages}/${product_id}`, { images })
  },
  resetProductState({ commit }) {
    commit('resetProductState')
  },
  getProductImages() {
    return axios.get(`${product.getImages}`)
  },
  updateProductAttribute(_, { params }) {
    return axios.post(`${product.updateProductAttribute}`, params)
  },
  createProductAttribute(_, { params }) {
    return axios.post(`${product.createProductAttribute}`, params)
  },
  deleteProductAttribute(_, { params }) {
    return axios.post(`${product.deleteProductAttribute}`, params)
  },
  deleteVideos(_, { product_id, videos }) {
    return axios.post(`${product.deleteVideos}/${product_id}`, { videos })
  },
  getDownloadProductTemplate() {
    return axios.get(`${product.downloadProductTemplate}`, {
      responseType: 'blob',
    })
  },
  uploadFile(_, { params }) {
    return axios.post(`${product.uploadFile}`, params)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
