<template>
  <CPagination
    :align="align"
    aria-label="Page navigation example"
    v-if="
      pageRenderred &&
      pageRenderred.firstPages &&
      pageRenderred.middlePages &&
      pageRenderred.lastPages
    "
  >
    <CPaginationItem
      class="cursor-pointer"
      @click="handleClickPrevious"
      :disabled="isFirstPage"
    >
      {{ $t('common.pagination.previous') }}
    </CPaginationItem>
    <template
      v-if="
        pageRenderred.firstPages.length > 0 &&
        ((page >= pageRenderred.firstPages[0] &&
          page <=
            pageRenderred.firstPages[pageRenderred.firstPages.length - 1]) ||
          page >= pageRenderred.lastPages[0])
      "
    >
      <CPaginationItem
        v-for="(_page, key) in pageRenderred.firstPages"
        @click="goPage(_page)"
        :key="key"
        :active="parseInt(_page) === parseInt(page)"
        class="cursor-pointer"
      >
        {{ _page }}
      </CPaginationItem>
    </template>
    <template v-else>
      <CPaginationItem class="cursor-pointer" @click="goFirstPage">
        «
      </CPaginationItem>
      <CPaginationItem>...</CPaginationItem>
    </template>
    <template v-if="pages > limit">
      <template v-if="pageRenderred.middlePages.length > 0">
        <template
          v-if="
            pageRenderred.middlePages.length > 0 &&
            page >= pageRenderred.middlePages[0] &&
            page <=
              pageRenderred.middlePages[pageRenderred.middlePages.length - 1]
          "
        >
          <CPaginationItem
            v-for="(_page, key) in [page - 1, page, page + 1]"
            @click="goPage(_page)"
            :key="key"
            :active="parseInt(_page) === parseInt(page)"
            class="cursor-pointer"
          >
            {{ _page }}
          </CPaginationItem>
        </template>
        <template v-else>
          <CPaginationItem>...</CPaginationItem>
        </template>
      </template>
      <template
        v-if="
          pageRenderred.lastPages.length > 0 &&
          (page >= pageRenderred.lastPages[0] ||
            page <=
              pageRenderred.firstPages[pageRenderred.firstPages.length - 1])
        "
      >
        <CPaginationItem
          v-for="(_page, key) in pageRenderred.lastPages"
          @click="goPage(_page)"
          :key="key"
          :active="parseInt(_page) === parseInt(page)"
          class="cursor-pointer"
        >
          {{ _page }}
        </CPaginationItem>
      </template>
      <template v-else>
        <CPaginationItem>...</CPaginationItem>
        <CPaginationItem class="cursor-pointer" @click="goLastPage">
          »
        </CPaginationItem>
      </template>
    </template>
    <CPaginationItem
      class="cursor-pointer"
      @click="handleClickNext"
      :disabled="isLastPage"
    >
      {{ $t('common.pagination.next') }}
    </CPaginationItem>
  </CPagination>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

const emits = defineEmits(['update:modelValue'])

const props = defineProps({
  pages: {
    type: Number,
    required: true,
  },
  modelValue: {
    type: Number,
    default: 1,
  },
  limit: {
    type: Number,
    default: 5,
  },
  align: {
    type: String,
    default: 'end',
  },
})

const page = ref(1)

const isFirstPage = computed(() => {
  return parseInt(page.value) === 1
})

const isLastPage = computed(() => {
  return parseInt(page.value) === parseInt(props.pages)
})

const pageRenderred = computed(() => {
  const pages = Array(props.pages)
    .fill(null)
    .map((_, i) => i + 1)

  return {
    firstPages: pages.splice(0, props.limit),
    lastPages: pages.reverse().splice(0, props.limit).reverse(),
    middlePages: pages.reverse().splice(0, pages.length),
  }
})

const goFirstPage = () => {
  page.value = 1
  onPageChanged()
}

const goLastPage = () => {
  const lastIndex = pageRenderred.value.lastPages.length - 1
  page.value = pageRenderred.value.lastPages[lastIndex]
  onPageChanged()
}

const goPage = (_page) => {
  page.value = _page
  onPageChanged()
}

const handleClickNext = () => {
  if (isLastPage.value) return

  if (page.value < props.pages) {
    page.value++
    onPageChanged()
  }
}

const handleClickPrevious = () => {
  if (isFirstPage.value) return

  if (page.value > 1) {
    page.value--
    onPageChanged()
  }
}

const onPageChanged = () => {
  emits('update:modelValue', page.value)
}

onMounted(() => {
  page.value = props.modelValue
})
</script>

<style lang="scss" scoped></style>
