<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle color="primary">
      <img :src="flag" class="ysl-flag" /> {{ language }}
    </CDropdownToggle>
    <CDropdownMenu>
      <CDropdownItem class="cursor-pointer" @click="switchLocale('en')">{{
        $t('languages.english')
      }}</CDropdownItem>
      <CDropdownItem class="cursor-pointer" @click="switchLocale('zh')">{{
        $t('languages.chinese')
      }}</CDropdownItem>
      <CDropdownItem class="cursor-pointer" @click="switchLocale('km')">{{
        $t('languages.khmer')
      }}</CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import en from '@/assets/flags/en.png'
import zh from '@/assets/flags/zh.png'
import km from '@/assets/flags/km.png'
import { $cookies } from '@/cookies'

export default {
  name: 'AppLanguageSwitcher',
  data() {
    return {
      en,
      km,
      zh,
    }
  },
  computed: {
    language() {
      const { locale } = this.$i18n
      if (locale === 'en') return this.$t('languages.english')
      else if (locale === 'zh') return this.$t('languages.chinese')
      else if (locale === 'km') return this.$t('languages.khmer')
      else return this.$t('languages.english')
    },
    locale() {
      return this.$i18n.locale
    },
    flag() {
      if (this.locale === 'km') return km
      else if (this.locale === 'zh') return zh
      else return en
    },
  },
  methods: {
    switchLocale(locale) {
      this.$i18n.locale = locale
      $cookies.set('locale', this.$i18n.locale)
    },
  },
}
</script>

<style lang="scss" scoped></style>
