import { mapFields } from 'vuex-map-fields'

const helpers = {
  install: (app) => {
    app.mixin({
      data() {
        return {
          config: process.env,
          digits: 6,
        }
      },
      computed: {
        ...mapFields('auth', {
          baseCurrency: 'shop.currency',
        }),
        baseCurrencyCode() {
          if (this.baseCurrency && this.baseCurrency.code) {
            return this.baseCurrency.code
          }
          return 'USD'
        },
      },
      methods: {
        toast(title, text, type = 'success') {
          const message = `<b>${title}</b><br/>${text}`
          if (type === 'success') {
            this.$toast.success(message)
          } else if (type === 'info') {
            this.$toast.info(message)
          } else if (type === 'warning') {
            this.$toast.warning(message)
          } else if (type === 'error') {
            this.$toast.error(message)
          } else {
            this.$toast(message)
          }
        },
        getProducts(arrays) {
          if (arrays.length === 0) {
            return [[]]
          }

          let results = []

          this.getProducts(arrays.slice(1)).forEach((product) => {
            arrays[0].forEach((value) => {
              results.push([value].concat(product))
            })
          })

          return results
        },
        getAllCombinations(attributes) {
          let attributeNames = Object.keys(attributes)

          let attributeValues = attributeNames.map((name) => attributes[name])

          return this.getProducts(attributeValues).map((product) => {
            let obj = {}
            attributeNames.forEach((name, i) => {
              obj[name] = product[i]
            })
            return obj
          })
        },
        onConfirm(options, callback) {
          this.$swal({
            title: this.$t('common.are_you_sure'),
            text: this.$t('common.are_you_sure_detail'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('common.are_you_sure_confirmed'),
            cancelButtonText: this.$t('button.cancel'),
            ...options,
          }).then((result) => {
            if (result.isConfirmed) {
              callback()
            }
          })
        },
        onDeleteSuccess(callback = null) {
          this.$swal({
            title: this.$t('common.are_you_sure_deleted'),
            text: this.$t('common.are_you_sure_deleted_success'),
            icon: 'success',
            confirmButtonText: this.$t('button.ok'),
          }).then(() => {
            callback()
          })
        },
        checkIfKeyExist(data, key) {
          return Object.keys(data).some((k) => k === key)
        },
        getFeedback(data, key) {
          if (typeof data === 'object') {
            if (this.checkIfKeyExist(data, key)) {
              return data[key][0]
            }
          }
          return null
        },
        onResponseError(error) {
          const { response } = error
          let text = this.$t('common.something_went_wrong')
          if (response && response.data && response.data.message) {
            text = response.data.message
          }

          if (
            response &&
            response.data &&
            response.data.error &&
            response.data.error.message
          ) {
            text = response.data.error.message
          }

          this.toast(this.$t('common.error'), text, 'error')
        },
        onResponseSuccess(message = null) {
          let title = this.$t('common.success')
          let text = this.$t('common.process_success')

          if (message) {
            if (message.title) title = message.title
            if (message.text) text = message.text
          }

          this.toast(title, text, 'success')
        },
        extractValidations({ response }) {
          if (response && response.data && response.data.errors) {
            return response.data.errors
          }
          return {}
        },
      },
    })
  },
}

export default helpers
